<template>
    <v-card outlined>
        <v-card-text>
            <v-row dense align="center" class="pa-2">
                <v-col cols="12" sm="6">
                    <v-autocomplete v-validate="'required'" dense v-model="datos.idCliente" :items="listaClientes"
                        item-text="rename" placeholder="INNOUT SAC" item-value="id"
                        :error-messages="errors.collect('datos.idCliente')" label="Buscar Clientes"
                        data-vv-name="datos.idCliente" data-vv-as="Tipo" filled rounded>
                    </v-autocomplete>
                </v-col>
                <v-col>
                    <ul>
                        <li>Correo: {{ datos.emailClient }}</li>
                        <li>Telefono: {{ datos.phoneClient }}</li>
                    </ul>
                </v-col>
                <v-col>
                    <v-btn class="green my-2" dark @click="generateOS">GENERAR ORDEN SERVICIO</v-btn>

                </v-col>
            </v-row>
        </v-card-text>
        <v-card-text>
            <v-row dense align="center" class="pa-2">
                <v-col cols="12" sm="12">
                    <v-row dense>
                        <v-col cols="12" sm="4">
                            <v-autocomplete dense v-model="isDepartamento" :items="departamentos" item-text="name"
                                placeholder="Departamento" item-value="id" label="Departamento" autocomplete="nope"
                                outlined />
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-autocomplete autocomplete="nope" dense v-model="isProvincia" :items="provincias"
                                item-text="name" placeholder="provincia" item-value="id" outlined label="Provincia" />
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-autocomplete autocomplete="nope" dense v-model="isDistrito" :items="distritos"
                                item-text="name" outlined item-value="id" label="Distrito" />
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" sm="6">
                    <v-text-field outlined dense v-model="datos.direccion" label="Dirección de envío"
                        placeholder="direccion" />
                </v-col>
                <v-col cols="12" sm="4">
                    <v-text-field outlined dense v-model="datos.atributos.documentoReceptor" label="DNI/RUC (Recibe)" />
                </v-col>
                <v-col cols="12" sm="4">
                    <v-text-field outlined dense v-model="datos.atributos.fullNameReceptor"
                        label="Nombre/Razon Social (Recibe)" />
                </v-col>
                <v-col cols="12" sm="4">
                    <v-text-field outlined dense v-model="datos.celularContacto" label="Celular (Recibe)" />
                </v-col>
                <v-col cols="12" sm="4">
                    <v-autocomplete outlined dense v-model="datos.atributos.encargadoOrdenServicio" :items="personal"
                        label="Encargado Orden Servicio"></v-autocomplete>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-text class="lightenPrimary">
            <v-card outlined>
                <v-tabs v-model="subTab" background-color="secondaryTheme" dark slider-color="primary" grow show-arrows>
                    <v-tab>SERVICIOS</v-tab>
                </v-tabs>
                <v-dialog max-width="100rem" dense v-model="openModalProveedor" persistent>
                    <CrearCliente @getIdClient="fnListener" @cleanCache="clientCache" />
                </v-dialog>
            </v-card>
        </v-card-text>
        <v-card-text class="grey lighten-4" elevation="2">
            <v-row>
                <v-col cols="12" sm="4">
                    <v-list two-line>
                        <template v-for="valProducts in this.listService">
                            <div :key="valProducts.id">
                                <v-list-item>
                                    <v-list-item-content>{{ valProducts.description }} </v-list-item-content>
                                    <v-list-item-action>
                                        <v-btn @click="getService(valProducts)" small>Agregar</v-btn>
                                    </v-list-item-action>
                                </v-list-item>
                            </div>
                        </template>
                    </v-list>
                </v-col>
                <v-col cols="12" class="grey lighten-5">
                    <v-data-table multi-sort :headers="headers" :items="listServiceItems" class="elevation-1"
                        :items-per-page="20" item-key="index">
                        <template v-slot:[`item.action`]="{ item, index }">
                            <v-icon color="primary" small @click="deleteItem(item, index)">mdi-delete </v-icon>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>
<script>

import { sleep } from '@/utils/genericUtils'
import { format, differenceInDays, parseISO } from 'date-fns'
import { mapGetters, mapState, mapActions } from 'vuex'
import CrearCliente from '../../components/clientes/CrearCliente'
import SelectPrivados from '@/components/selects/SelectPrivados'
import SelectProductos from '@/components/selects/SelectProductos'
import { mixins } from '@/mixins/mixin.js'

export default {
    components: {
        SelectPrivados,
        CrearCliente,
        SelectProductos
    },
    filters: {},
    mixins: [mixins],
    $_veeValidate: {
        validator: 'new'
    },
    data() {
        return {
            subTab: 0,
            date: new Date(),
            dialog: false,
            textHours: '',
            openModalProveedor: false,
            listAllService: this.datos?.service || null,
            menu: false,
            menu2: false,
            menu3: false,
            isDepartamento: null,
            isProvincia: null,
            isDistrito: null,
            time: format(new Date(), 'HH:mm'),
            selectCliente: [
                { id: 1, text: 'NUEVO' },
                { id: 2, text: 'FRECUENTE' }
            ],
            customData: null,
            listaProdReserva: [],
            headers: [
                { text: 'SLOT', value: 'rename' },
                { text: 'Descripción', value: 'description' },
                { text: 'Duración', value: 'hoursTime' },
                { text: 'UNID', value: 'quantity' },
                { text: 'P. UNIT (S/)', value: 'price' },
                { text: 'Comision', value: 'comision' },
                { text: 'Descuento', value: 'descuentos' },

                { text: 'SUBTOTAL', value: 'subTotal' },
                { text: 'Actions', value: 'action', sortable: false }
            ]
        }
    },
    computed: {
        ...mapState('ventas', ['datos']),
        ...mapState('users', ['user', 'personal']),


        ...mapState('sedes', ['sedes', 'departamentos', 'provincias', 'distritos']),
        ...mapGetters('clientes', ['listaClientes']),
        check_in: {
            get() {
                return this.datos.check_in
            },
            set(v) {
                //console.log('set')
                this.$store.commit('ventas/UPDATE_DATOS', {
                    check_in: format(parseISO(v), 'yyyy-MM-dd')
                })
            }
        },
        listService() {
            let service = []
            if (this.datos.productos?.length > 0) {
                this.datos.productos.forEach((element) => {
                    if (element.unit_type_id == 'ZZ') {
                        service.push(element)
                    }
                })
            }
            return service
        },
        listServiceItems() {
            const list = []
            //console.log('payload---', this.listAllService)
            list.push(this.listAllService)
            this.datos.service = list
            //console.log("datos--",this.datos)
            return list
        },
        idCliente() {
            return this.datos.idCliente
        }
    },
    async mounted() {
        this.customData = null
        await this.$store.dispatch('utilities/itemServices')
    },
    watch: {
        async isDepartamento(val) {
            //console.log('isDepartamento ', val)
            if (val) {
                await this.$store.dispatch('sedes/getProvincia', val)
            }
        },
        async isProvincia(val) {
            //console.log('provincia ', val)
            if (val) {
                await this.$store.dispatch('sedes/getDistrito', val)
            }
        },
        async isDistrito(val) {
            //console.log('distrito ', val)
            if (val) {
                this.$store.commit('ventas/UPDATE_DATOS', {
                    ubigeo: val
                })
            }
        },
        check_in(val) {
            const validaDate = differenceInDays(parseISO(val), format(new Date(), 'yyyy-MM-dd')) > 0
            if (validaDate) {
                this.$store.commit('ventas/UPDATE_DATOS', {
                    tipoVenta: 2
                })
            } else {
                this.$store.commit('ventas/UPDATE_DATOS', {
                    tipoVenta: 1
                })
            }
        },
        idCliente(val) {
            if (val) {
                const findCliente = this.listaClientes.find((x) => x.id === val)
                this.$store.commit('ventas/UPDATE_DATOS', {
                    numeroDocumento: findCliente.number,
                    emailClient: findCliente.email,
                    phoneClient: findCliente.telephone,
                    full_name: findCliente.defaultName,
                    idTipoDocumento: findCliente.identity_document_type_id
                })
            }
        }
    },
    async created() {
        this.$store.dispatch('clientes/getListaCli', { active: 0 })
        this.datos.atributos.DocumentoRegistro = { ...this.datos.atributos, ...this.datos.atributos.DocumentoRegistro }
        await Promise.all([
            this.$store.dispatch('sedes/getDepartamento', { online: true }),
            this.$store.dispatch('users/getPersonas'),
            this.$store.dispatch('users/getLista')
        ])

        if (this.datos.ubigeo) {
            this.isDepartamento = this.datos.ubigeo.slice(0, 2)
            this.isProvincia = this.datos.ubigeo.slice(0, 4)
            this.isDistrito = this.datos.ubigeo
        }
    },
    methods: {
        ...mapActions({ postAddOS: 'inventario/postAddOS' }),
        async generateOS() {
            if (this.isProvincia && this.isDepartamento && this.isDistrito && this.datos.atributos.fullNameReceptor) {
                const departamento = this.departamentos.find((x) => x.id == this.isDepartamento).name
                const provincia = this.provincias.find((x) => x.id == this.isProvincia).name
                const distrito = this.distritos.find((x) => x.id == this.isDistrito).name
                const sede = this.sedes.find((x) => x.id == this.user.idSede).sede_name_id
                const custom_data = {
                    numeroDocumento: this.datos.numeroDocumento,

                    id: this.datos.id,
                    full_name: this.datos.full_name,
                    service: this.datos.service,
                    importeAbonadoSoles: this.datos.importeAbonadoSoles,
                    sede,
                    atributos: this.datos.atributos,
                    correo: this.user.correo,
                    celularVendedora: this.user.telefono,
                    celularContacto: this.datos.celularContacto,
                    direccion: this.datos.direccion,
                    departamento,
                    provincia,
                    distrito,
                    vendedora: this.user.nombres + '' + this.user.apellidos,

                }
                const payload = {
                    personalCargo: this.datos.atributos.encargadoOrdenServicio,
                    fechaEmision: new Date(),
                    ordenPedido: this.datos.id,
                    producto: this.listService[0].description,
                    items: this.listService,
                    customData: custom_data,
                    status: 1
                }
                const result = await this.postAddOS(payload)
                await this.$store.dispatch('printer/ordenService', { ...custom_data, id: result.data.id })
            } else {
                this.$store.commit('mainUI/OPEN_MODAL', {
                    state: true,
                    text: 'Complete todos los campos para continuar',
                    color: 'green'
                })
                await sleep(2500)
                this.$store.commit('mainUI/OPEN_MODAL', { state: false })
            }


        },
        getService(payload) {
            //console.log('payload....', payload)
            const body = {
                ...payload,
                rename: payload.massage,
                description: payload.description,
                hoursTime: payload.hours,
                quantity: payload.quantity,
                price: payload.price,
                comision: payload.comision,
                descuentos: payload.descuentos,
                subTotal: payload.subTotal
            }
            this.listAllService = body
        },

        fnListener(v) {
            this.$store.commit('ventas/UPDATE_DATOS', {
                idCliente: v
            })
            console.log('datos_fnListener', this.datos)
            this.openModalProveedor = false
        },
        clientCache() {
            this.openModalProveedor = false
        }
    }
}
</script>
